.stat-card {
  position: relative; /* Ensures that positioned pseudo-elements are contained within the card */
  width: 100%;
}

.border-r-custom::after {
  position: absolute;
  top: 15%; /* Changed from 20% to 0 */
  right: 1%;
  bottom: 15%; /* Changed from 20% to 0 */
  z-index: 1;
  width: 1px;
  content: "";
  background-color: #ccc;
}

.stats-section {
  padding: 20px 120px;
  margin-top: 24px;
  text-align: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px 2px;
  padding: 35px 45px;
}
