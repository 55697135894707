@keyframes scrollLogo {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.logo-container {
  display: flex;
  white-space: nowrap;
  align-items: center;
  padding: 20px 0;
  animation: scrollLogo 80s linear infinite;
  position: relative;
  z-index: 1;
  gap: 50px; /* Ensure it's under the fade covers */
}

.logo-item {
  flex-shrink: 0; /* Prevent logos from being squished */
}

.logo-item:hover {
  animation-play-state: paused;
}

.logo-item-text-right {
  display: flex;
  align-items: center; /* Centers the text vertically relative to the icon */
  gap: 14px; /* Adds some space between the icon and the text */
}

.logo-item-text-right img,
.logo-item-text-right svg {
  flex-shrink: 0; /* Prevents the image from resizing unexpectedly */
}

.logo-item-text-right span {
  white-space: nowrap; /* Ensures the text stays on one line */
  font-size: 1em;
}

.viewport {
  width: 100%; /* Set the width of the viewport to 100% */
  overflow-x: hidden; /* Hide the overflow */
}
